import React from 'react';
import {
  makeStyles,
  createStyles,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const Preloader: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default Preloader;
